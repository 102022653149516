/* tslint:disable */
/* eslint-disable */
/**
 * Albatros CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
    import {
        ApiFooterContent,
        ApiFooterContentFromJSON,
        ApiFooterContentFromJSONTyped,
        ApiFooterContentToJSON,
        ApiImage,
        ApiImageFromJSON,
        ApiImageFromJSONTyped,
        ApiImageToJSON,
        ApiPage,
        ApiPageFromJSON,
        ApiPageFromJSONTyped,
        ApiPageToJSON,
        ApiVideo,
        ApiVideoFromJSON,
        ApiVideoFromJSONTyped,
        ApiVideoToJSON,
    } from './';

/**
* 
* @export
* @interface ApiTravelType
*/
export interface ApiTravelType {
    /**
    * 
    * @type {string}
    * @memberof ApiTravelType
    */
    id?: string;
    /**
    * 
    * @type {ApiImage}
    * @memberof ApiTravelType
    */
    teaserImage?: ApiImage;
    /**
    * 
    * @type {string}
    * @memberof ApiTravelType
    */
    name?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiTravelType
    */
    nameDK?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiTravelType
    */
    nameFI?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiTravelType
    */
    namePL?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiTravelType
    */
    nameNO?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiTravelType
    */
    nameSE?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiTravelType
    */
    teaserShortDescription?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiTravelType
    */
    introTitle?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiTravelType
    */
    productTeaserTitle?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiTravelType
    */
    introText?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiTravelType
    */
    generalPageUrlDK?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiTravelType
    */
    generalPageUrlFI?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiTravelType
    */
    generalPageUrlNO?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiTravelType
    */
    generalPageUrlPL?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiTravelType
    */
    generalPageUrlSE?: string;
    /**
    * 
    * @type {ApiImage}
    * @memberof ApiTravelType
    */
    introImage?: ApiImage;
    /**
    * 
    * @type {ApiPage}
    * @memberof ApiTravelType
    */
    travelTypePage?: ApiPage;
    /**
    * 
    * @type {ApiPage}
    * @memberof ApiTravelType
    */
    travelTypePageDK?: ApiPage;
    /**
    * 
    * @type {ApiPage}
    * @memberof ApiTravelType
    */
    travelTypePageFI?: ApiPage;
    /**
    * 
    * @type {ApiPage}
    * @memberof ApiTravelType
    */
    travelTypePageNO?: ApiPage;
    /**
    * 
    * @type {ApiPage}
    * @memberof ApiTravelType
    */
    travelTypePagePL?: ApiPage;
    /**
    * 
    * @type {ApiPage}
    * @memberof ApiTravelType
    */
    travelTypePageSE?: ApiPage;
    /**
    * 
    * @type {string}
    * @memberof ApiTravelType
    */
    content?: string;
    /**
    * 
    * @type {ApiVideo}
    * @memberof ApiTravelType
    */
    heroBackgroundVideo?: ApiVideo;
    /**
    * 
    * @type {ApiImage}
    * @memberof ApiTravelType
    */
    heroBackgroundImage?: ApiImage;
    /**
    * 
    * @type {number}
    * @memberof ApiTravelType
    */
    products?: number;
    /**
    * 
    * @type {ApiFooterContent}
    * @memberof ApiTravelType
    */
    footerTopLeft?: ApiFooterContent;
    /**
    * 
    * @type {ApiFooterContent}
    * @memberof ApiTravelType
    */
    footerTopRight?: ApiFooterContent;
    /**
    * 
    * @type {string}
    * @memberof ApiTravelType
    */
    themeIntro?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiTravelType
    */
    themeProductTeaser?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiTravelType
    */
    salesPriority?: string;
}

export function ApiTravelTypeFromJSON(json: any): ApiTravelType {
return ApiTravelTypeFromJSONTyped(json, false);
}

export function ApiTravelTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiTravelType {
    if ((json === undefined) || (json === null)) {
    return json;
    }
    return {
    
                    'id': json['id'],
                            'teaserImage': ApiImageFromJSON(json['teaserImage']),
                    'name': json['name'],
                    'nameDK': json['nameDK'],
                    'nameFI': json['nameFI'],
                    'namePL': json['namePL'],
                    'nameNO': json['nameNO'],
                    'nameSE': json['nameSE'],
                    'teaserShortDescription': json['teaserShortDescription'],
                    'introTitle': json['introTitle'],
                    'productTeaserTitle': json['productTeaserTitle'],
                    'introText': json['introText'],
                    'generalPageUrlDK': json['generalPageUrlDK'],
                    'generalPageUrlFI': json['generalPageUrlFI'],
                    'generalPageUrlNO': json['generalPageUrlNO'],
                    'generalPageUrlPL': json['generalPageUrlPL'],
                    'generalPageUrlSE': json['generalPageUrlSE'],
                            'introImage': ApiImageFromJSON(json['introImage']),
                            'travelTypePage': ApiPageFromJSON(json['travelTypePage']),
                            'travelTypePageDK': ApiPageFromJSON(json['travelTypePageDK']),
                            'travelTypePageFI': ApiPageFromJSON(json['travelTypePageFI']),
                            'travelTypePageNO': ApiPageFromJSON(json['travelTypePageNO']),
                            'travelTypePagePL': ApiPageFromJSON(json['travelTypePagePL']),
                            'travelTypePageSE': ApiPageFromJSON(json['travelTypePageSE']),
                    'content': json['content'],
                            'heroBackgroundVideo': ApiVideoFromJSON(json['heroBackgroundVideo']),
                            'heroBackgroundImage': ApiImageFromJSON(json['heroBackgroundImage']),
                    'products': json['products'],
                            'footerTopLeft': ApiFooterContentFromJSON(json['footerTopLeft']),
                            'footerTopRight': ApiFooterContentFromJSON(json['footerTopRight']),
                    'themeIntro': json['themeIntro'],
                    'themeProductTeaser': json['themeProductTeaser'],
                    'salesPriority': json['salesPriority'],
        };
    }

    export function ApiTravelTypeToJSON(value?: ApiTravelType | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {

                'id': value.id,
                            'teaserImage': ApiImageToJSON(value.teaserImage),
                'name': value.name,
                'nameDK': value.nameDK,
                'nameFI': value.nameFI,
                'namePL': value.namePL,
                'nameNO': value.nameNO,
                'nameSE': value.nameSE,
                'teaserShortDescription': value.teaserShortDescription,
                'introTitle': value.introTitle,
                'productTeaserTitle': value.productTeaserTitle,
                'introText': value.introText,
                'generalPageUrlDK': value.generalPageUrlDK,
                'generalPageUrlFI': value.generalPageUrlFI,
                'generalPageUrlNO': value.generalPageUrlNO,
                'generalPageUrlPL': value.generalPageUrlPL,
                'generalPageUrlSE': value.generalPageUrlSE,
                            'introImage': ApiImageToJSON(value.introImage),
                            'travelTypePage': ApiPageToJSON(value.travelTypePage),
                            'travelTypePageDK': ApiPageToJSON(value.travelTypePageDK),
                            'travelTypePageFI': ApiPageToJSON(value.travelTypePageFI),
                            'travelTypePageNO': ApiPageToJSON(value.travelTypePageNO),
                            'travelTypePagePL': ApiPageToJSON(value.travelTypePagePL),
                            'travelTypePageSE': ApiPageToJSON(value.travelTypePageSE),
                'content': value.content,
                            'heroBackgroundVideo': ApiVideoToJSON(value.heroBackgroundVideo),
                            'heroBackgroundImage': ApiImageToJSON(value.heroBackgroundImage),
                'products': value.products,
                            'footerTopLeft': ApiFooterContentToJSON(value.footerTopLeft),
                            'footerTopRight': ApiFooterContentToJSON(value.footerTopRight),
                'themeIntro': value.themeIntro,
                'themeProductTeaser': value.themeProductTeaser,
                'salesPriority': value.salesPriority,
    };
    }


    // custom code here ##################################################################################################################
    // function toUTC(date) {
    //   var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
    //   return new Date(date_utc);
    // }

