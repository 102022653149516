import {
  MagnoliaDamNode,
  MagnoliaImages,
  MagnoliaPageNode,
  MagnoliaVideos,
  MetaRobots
} from '@/types/cms/magnolia';
import {
  ApiCity,
  ApiContentArea,
  ApiCountry,
  ApiImage,
  ApiPage,
  ApiTravelType,
  ApiVideoContent
} from '../../api/model';
import { MagnoliaTravelTypeNode } from '@/types/cms/travelType';
import { ContentRowProps } from '@/types/cms/country';
import {
  ApiExtraService,
  ApiHotelRoom,
  ApiProductsCacheData,
  ApiProductsCacheDataFromJSON,
  ApiTransfer
} from '@ibe/api';
import { SearchParamsQuery } from '@/components/Search/helpers';
import { MagnoliaContinentNode } from '@/types/cms/continent';

export const isMagnoliaDamNode = (node: object | MagnoliaDamNode): node is MagnoliaDamNode => {
  return (
    (node as MagnoliaDamNode)['@link'] !== undefined &&
    (node as MagnoliaDamNode).metadata !== undefined
  );
};
export const isMagnoliaImages = (item: MagnoliaImages | ApiImage[]): item is MagnoliaImages => {
  return item ? item.hasOwnProperty('@nodes') : false;
};

export const isMagnoliaPage = (item: MagnoliaPageNode | ApiPage): item is MagnoliaPageNode => {
  return item ? item.hasOwnProperty('@nodeType') : false;
};

export const isMagnoliaTravelType = (
  item: MagnoliaTravelTypeNode | ApiTravelType
): item is MagnoliaTravelTypeNode & { field: ApiTravelType } => {
  return item ? item.hasOwnProperty('@nodes') : false;
};

export const isMagnoliaContentRow = (
  item: ApiContentArea | ContentRowProps
): item is ContentRowProps | ApiContentArea => {
  return item.imageLeft
    ? item.imageLeft?.hasOwnProperty('@nodes')
    : item.imageRight
      ? item.imageRight?.hasOwnProperty('@nodes')
      : item.textLeft
        ? item.textLeft?.hasOwnProperty('@nodes')
        : item.textRight
          ? item.textRight?.hasOwnProperty('@nodes')
          : false;
};

export const isMagnoliaVideos = (
  item: MagnoliaVideos | ApiVideoContent[]
): item is MagnoliaVideos => {
  return item.hasOwnProperty('@nodes');
};

export const MetaRobotsTypeGuard = (robots: string[] | MetaRobots): robots is MetaRobots => {
  return typeof robots === 'object' && robots.hasOwnProperty('follow');
};

const emptyProduct = ApiProductsCacheDataFromJSON({});
type productsCacheDataProperties = keyof ApiProductsCacheData;

export const isProductsCacheDataProp = (value: string): value is productsCacheDataProperties => {
  return emptyProduct.hasOwnProperty(value);
};

export const isSearchParamsQuery = (value: unknown): value is SearchParamsQuery => {
  return (
    !!value &&
    typeof value === 'object' &&
    (value.hasOwnProperty('travelType') ||
      value.hasOwnProperty('date') ||
      value.hasOwnProperty('continent'))
  );
};

export const isMagnoliaContinentNode = (item: any): item is MagnoliaContinentNode => {
  return item.hasOwnProperty('@id') && item.hasOwnProperty('@name') && item.hasOwnProperty('name');
};

export const isExtraService = (item: any): item is ApiExtraService => {
  return item.hasOwnProperty('serviceType');
};

export const isTransfer = (item: any): item is ApiTransfer => {
  return item.hasOwnProperty('category') && item.hasOwnProperty('pickupStation');
};

export const isHotelRoom = (item: any): item is ApiHotelRoom => {
  return item.hasOwnProperty('roomrates');
};

export const isCountry = (item: ApiCity | ApiCountry): item is ApiCountry => {
  return item.hasOwnProperty('cities') && item.hasOwnProperty('metaInformationLightSightseeing');
};
