'use client';

import { facButtonCaret } from '@/Theme/SVG/Icons';
import React, { PropsWithChildren, JSX } from 'react';
import { Button as ReactstrapButton, ButtonProps } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Button = (props: PropsWithChildren<ButtonProps & { showCaret?: boolean }>): JSX.Element => {
  const { showCaret, ...rest } = props;
  const { children } = rest;

  return (
    <ReactstrapButton {...rest}>
      <span>{children}</span>
      {showCaret && <FontAwesomeIcon className="button-caret" icon={facButtonCaret} />}
    </ReactstrapButton>
  );
};

export default Button;
