import { MouseEvent } from 'react';
import { SessionStorage } from '@ibe/services';
import {
  MagnoliaDamNode,
  MagnoliaNode,
  MagnoliaPageNode,
  MagnoliaSite
} from '@/types/cms/magnolia';
import {
  ApiContentArea,
  ApiContentAreaContent,
  ApiImage,
  ApiImageFromJSON,
  ApiPage,
  ApiTravelType
} from '../../api/model';
import {
  isMagnoliaContentRow,
  isMagnoliaDamNode,
  isMagnoliaPage,
  isMagnoliaTravelType
} from '@/types/typeGuards';
import { MagnoliaTravelTypeNode } from '@/types/cms/travelType';
import { ContentRowProps } from '@/types/cms/country';
import { ApiPrice, ApiPriceModifierType, ApiProductsCacheResponse } from '@ibe/api';
import {
  currencyMapping,
  defaultNumberFormat,
  specialNumberFormat
} from '@/app/i18n/settingsClient';
import dayjs from 'dayjs';
import { ReducerState } from '@/components/Search/searchReducer/types';
import { fallbackLng, languageMapping } from '@/app/i18n/settingsClient';
import { ExtendedProduct } from '@/components/productTeasers/ProductTeaser';
import { getEarliestProduct } from '@/components/productTeasers/getEarliestProduct';

const inheritedKeyRegex = /_inherited_depth_\d+$/;
const magnoliaUrl: string = process.env.MAGNOLIA_HOST ?? '';
export const ROOT_PORTAL_CONTENT_CONTAINER = 'magnolia-page-root-portal-content-container';
export const ROOT_PORTAL_CONTAINER = 'magnolia-page-root-portal-container';
export const PAGE_TOP_CONTENT_CONTAINER = '.page__top';
export const VARIANT_QUERY_PROP = 'variant';
export const VARIANT_QUERY_PROP_DELIMITER = '|';
export const FIND_FIRST_AVAILABLE_PARAMETER = 'available';

export const SHERPA_CONTAINER_ID = 'sherpa-trip-element';
export const SHERPA_CUSTOM_EVENT = 'sherpa:custom:notification';

export const PRODUCT_REVIEW_CONTAINER_ID = 'alb-product-review-container';

export const searchDataSessionStorage = new SessionStorage<ReducerState['items']>(
  'alb-search-data-session-storage'
);

export const PACKAGE_CART_ID_PARAMETER = 'packge_cart_id';
export const FROM_SEARCH_PARAMETER = 'from_search';
export const SEARCH_QUERY_PARAMETER = 'search';
export const CHECKOUT_PARAMS_PARAMETER = 'checkout_params';

export const CHECKOUT_FORM_NOTES_MEDIA_CODE = '02_MC';
export const CHECKOUT_FORM_NOTES_ADVERTISING_CODE = '03_AC';

export const PUBLIC_CONFIG_NODE_PATH = '/fe-public-config';

export const CHECKOUT_BOOKING_ID_QUERY_PARAM = 'bookingId';
export const CHECKOUT_STATUS_QUERY_PARAM = 'status';

export const SEATMAP_IMAGE_REQUEST_SESSION_ID = 'Administrator_Service_Component_Login';

export const YOUTUBE_NO_COOKIE_HOST = 'https://www.youtube-nocookie.com';

export const FAQ_TEMPLATE = 'website:pages/faq';

export const INTEREST_LIST_HIDDEN_FIELD_NAME = 'interestListProductCode';
export const INTEREST_LIST_URL_PARAMETER = 'productCode';

export const WEBSITE_SEARCH_TYPE_PARAM = 'type';
export const WEBSITE_SEARCH_VALUE_PARAM = 'value';
export const WEBSITE_SEARCH_HEADLINE_PARAM = 'search';

export const NOTES_TYPE_CODE = 'WEB';
export const NOTES_TYPE_CANCEL_INS = 'CXL';
export const NOTES_TYPE_ADDITIONAL_INS = 'TRV';

export const REVALIDATION_TAG = 'iso-revalidate-all-fetches';

export const IGNORED_BASE_DATA_SALUTATIONS = ['Child', 'Baby'];

export enum API_ITEM_SERVICE_CODE {
  SINGLE_EXCURSION = 'EXC_E',
  EXCURSION_PACKAGE = 'EXCP_E',
  HOTEL = 'LA',
  HOTEL_ALT1 = 'CR',
  HOTEL_ALT2 = 'HT',
  CRUISE = 'CR',
  OPERA = 'OP',
  MEAL = 'MEAL_E',
  VISA = 'VIS_E',
  EXTENSION_PACKAGE = 'EXTP_E',
  EXTENSION_PACKAGE_ALT1 = 'EXT_E',
  EXTENSION_EXCURSION = 'EXC',
  EXTENSION_EXCURSION_PACKAGE = 'EXCP',
  EXTENSION_HOTEL = 'HT_E',
  BU = 'BUS',
  EXTRA = 'EXTRA',
  FO_EXTRA = 'FO_E',
  TRF_EXTRA = 'TRF_E',
  FLIGHT = 'EXTFL'
}

export const enum PRODUCT_AVAILABILITY_STATE {
  pastTripNoFutureAvail = 'pastTripNoFutureAvail',
  pastTripWithFutureAvail = 'pastTripWithFutureAvail',
  soldOutNoFutureAvail = 'soldOutNoFutureAvail',
  soldOutWithFutureAvail = 'soldOutWithFutureAvail',
  default = 'default'
}

export const NEWSLETTER_SOURCE = {
  footer: 'footer_signup',
  checkout: 'checkout_signup'
};

export const VIP_COUNTRIES = ['FO', 'DK', 'FI', 'GL', 'NO', 'PL', 'SE'];

export const apis = {
  base: magnoliaUrl + (process.env.MAGNOLIA_API_BASE ?? '/.rest'),
  site: magnoliaUrl + (process.env.MAGNOLIA_API_SITE ?? '/.rest/site/v1'),
  templateAnnotations:
    magnoliaUrl + (process.env.MAGNOLIA_API_TEMPLATES ?? '/.rest/template-annotations/v1'),
  pages: magnoliaUrl + (process.env.MAGNOLIA_API_PAGES ?? '/.rest/delivery/pages/v1'),
  config: magnoliaUrl + (process.env.MAGNOLIA_API_CONFIG ?? '/.rest/delivery/config/'),
  translationsVersion: magnoliaUrl + '/.rest/translations/version'
};

export const magnoliaPath = '/.magnolia/admincentral';

export function checkPathCompatibility(longPath: string, shortPath: string): boolean {
  const longPathParts = longPath?.split('/')?.filter(Boolean) || [];
  const shortPathParts = shortPath?.split('/')?.filter(Boolean) || [];

  let lastIndex = -1;
  for (const part of shortPathParts) {
    const currentIndex = longPathParts.indexOf(part, lastIndex + 1);
    if (currentIndex === -1 || (lastIndex !== -1 && currentIndex !== lastIndex + 1)) {
      return false;
    }
    lastIndex = currentIndex;
  }
  return true;
}

export const hasComponent = (dataNode: Record<string, any>, componentNames: string[]): boolean => {
  return Object.keys(dataNode).some(key => {
    const node = dataNode[key];
    if (typeof node === 'string' && key === 'mgnl:template' && componentNames.includes(node)) {
      return true;
    } else {
      return (
        typeof node === 'object' &&
        !Array.isArray(node) &&
        node !== null &&
        hasComponent(node, componentNames)
      );
    }
  });
};

export const TRAVELER_FORM_ADULT_LEGAL_MIN_AGE = 18;

export const MEDIAQUERY_DEFAULTS = {
  xs: '0',
  xsMax: '319',
  sm: '320',
  smMax: '599',
  md: '600',
  mdMax: '767',
  xm: '768',
  xmMax: '991',
  lg: '992',
  lgMax: '1199',
  xl: '1200',
  xlMax: '1439',
  xxl: '1440'
} as const;

export enum THEMES {
  green = 'theme-green',
  sand = 'theme-sand',
  mediumGreen = 'theme-medium-green',
  darkGreen = 'theme-dark-green',
  yellow = 'theme-yellow',
  blue = 'theme-blue',
  white = 'theme-white'
}

export enum PRODUCT_SORT_ORDER {
  default = 'default',
  accommodation = 'accommodation'
}

export const smoothScroll = (
  itemId: string,
  e?: MouseEvent<HTMLAnchorElement>,
  callback?: () => void
): void => {
  e?.preventDefault();
  const element = document.getElementById(itemId);
  if (!!element) {
    element.scrollIntoView({ behavior: 'smooth' });
    window?.history.replaceState(undefined, '', `#${itemId}`);
    if (!!callback) {
      callback();
    }
  }
};

export const getDefaultFormatPrice = (price: number, locale: string): string => {
  try {
    const formatOptions = {
      style: 'decimal' as keyof (keyof Intl.NumberFormatOptionsStyleRegistry) | undefined,
      useGrouping: true
    };
    const formatter =
      price % 1 === 0
        ? new Intl.NumberFormat(languageMapping[locale].language, {
            ...formatOptions,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          } as Intl.NumberFormatOptions)
        : new Intl.NumberFormat(languageMapping[locale].language, {
            ...formatOptions,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          } as Intl.NumberFormatOptions);
    return specialNumberFormat.includes(locale)
      ? `${formatter.format(price).replace(/\./g, ' ').replace(/,/g, '.')}${
          currencyMapping[locale] || ''
        }`
      : defaultNumberFormat.includes(locale)
        ? `${formatter.format(price).replace(/\./g, ',').replace(/\s+/g, '.')}${
            currencyMapping[locale] || ''
          }`
        : `${formatter.format(price)}${currencyMapping[locale] || ''}`;
  } catch (err) {
    logger()('NumberFormatError: ', err);
    return '';
  }
};

export const normalizeInheritedProps = <T extends MagnoliaNode>(props: T): T => {
  const normalizedProps = { ...props };

  if (normalizedProps['@name']) {
    normalizedProps['@name']?.replace(inheritedKeyRegex, '');
  }
  normalizedProps['@nodes']?.map((item: string) => item.replace(inheritedKeyRegex, ''));

  Object.entries(props).forEach(([key, value]) => {
    if (key.match(inheritedKeyRegex)) {
      const replacedKey = key.replace(inheritedKeyRegex, '') as keyof T;
      if (typeof value === 'object') {
        normalizedProps[replacedKey] = normalizeInheritedProps<typeof value>(value);
      } else {
        normalizedProps[replacedKey] = value;
      }
    }
  });
  return normalizedProps;
};

export const mapToApiImage = (
  image?: MagnoliaDamNode | ApiImage,
  imageCaption?: string
): ApiImage | undefined => {
  const mapImage = (image: MagnoliaDamNode): ApiImage => {
    return ApiImageFromJSON({
      title: image['metadata'].title,
      imageLink: image['@link'],
      caption: imageCaption || image['metadata'].caption,
      height: image.metadata.height,
      width: image.metadata.width
    });
  };
  return !!image ? (isMagnoliaDamNode(image) ? mapImage(image) : image) : undefined;
};

export const mapToApiPage = (
  rootNodePath: string,
  page?: MagnoliaPageNode | ApiPage
): ApiPage | undefined => {
  const mapPage = (page: MagnoliaPageNode): ApiPage => {
    return {
      title: page.title,
      url: page['@path'].replace(rootNodePath, ''),
      id: page['@id']
    };
  };
  return !!page ? (isMagnoliaPage(page) ? mapPage(page) : page) : undefined;
};
export const mapToApiTravelType = (
  rootNodePath: string,
  travelType?: MagnoliaTravelTypeNode | ApiTravelType
): ApiTravelType | undefined => {
  const mapTravelType = (travelType: MagnoliaTravelTypeNode): ApiTravelType => {
    return {
      content: travelType.travelType?.content,
      teaserImage: mapToApiImage(travelType.travelType.teaserImage),
      name: travelType.travelType.travelTypeName,
      heroBackgroundVideo: travelType.travelType.heroBackgroundvideo,
      heroBackgroundImage: mapToApiImage(travelType.travelType.heroBackgroundimage),
      travelTypePage: mapToApiPage(rootNodePath, travelType.travelType?.travelTypePage),
      salesPriority: travelType.travelType.salesPriority || '50'
    };
  };
  return !!travelType
    ? isMagnoliaTravelType(travelType)
      ? (mapTravelType(travelType) as ApiTravelType)
      : (travelType as ApiTravelType)
    : undefined;
};

export const mapToApiContentRow = (
  contentRow?: ContentRowProps | ApiContentArea
): ApiContentArea | undefined => {
  const mapContentRow = (contentRow: ContentRowProps): ApiContentArea => {
    return {
      imageLeft: mapToApiImage(contentRow.imageLeft?.imageLink, contentRow.imageLeft?.imageCaption),
      imageRight: mapToApiImage(
        contentRow.imageRight?.imageLink,
        contentRow.imageRight?.imageCaption
      ),
      leftSide: contentRow.leftSide as string | undefined,
      rightSide: contentRow.rightSide as string | undefined,
      textLeft: contentRow.textLeft as ApiContentAreaContent | undefined,
      textRight: contentRow.textRight as ApiContentAreaContent | undefined
    };
  };
  return !!contentRow
    ? isMagnoliaContentRow(contentRow)
      ? (mapContentRow(contentRow as ContentRowProps) as ApiContentArea)
      : (contentRow as ApiContentArea)
    : undefined;
};

export const getProductPageBasePath = (rootNodePath: string, siteConfig?: MagnoliaSite): string => {
  return siteConfig?.parameters?.productPage?.replace(rootNodePath, '')?.substring(1) || '';
};

export const getResultsPageBasePath = (rootNodePath: string, siteConfig?: MagnoliaSite): string => {
  return siteConfig?.parameters?.resultsPage?.replace(rootNodePath, '')?.substring(1) || '';
};

export const getLocalizedNumberFormat = (language: string) => {
  const formatter = new Intl.NumberFormat(language);

  return (number: number) => {
    return formatter.format(number);
  };
};

export const getCountryCodeFromMarket = (market: string) => {
  return market.split('-')[market.split('-').length - 1].toUpperCase();
};

export const getPrice = (price: ApiPrice, language?: string): string => {
  const participantModifier = price.modifiers.find(
    modifier => modifier.type === ApiPriceModifierType.PARTICIPANT
  );
  return getDefaultFormatPrice(
    participantModifier?.absolute || price.finalPrice || 0,
    language || fallbackLng
  );
};

export const scrollIntoViewWithOffset = (
  selector: string,
  window?: Window | null,
  offset?: number
) => {
  const foundElement = window?.document?.querySelector(selector);
  if (!!foundElement) {
    window?.scrollTo({
      behavior: 'smooth',
      top:
        (foundElement.getBoundingClientRect()?.top || 0) -
        (window?.document?.body?.getBoundingClientRect()?.top || 0) -
        (offset || 0)
    });
  }
};

export const logLevels = ['error', 'warn', 'debug', 'log', 'info', 'mute'] as const;
export type LogLevel = (typeof logLevels)[number];

export const logger =
  (type: LogLevel = 'log') =>
  (...values: (string | unknown)[]) => {
    const logLevel = process.env.LOG_LEVEL || 'log';
    const { debug, error, warn, log, info } = console;
    let logFn;
    switch (type) {
      case 'error':
        logFn =
          logLevel === 'error' ||
          logLevel === 'warn' ||
          logLevel === 'debug' ||
          logLevel === 'info' ||
          logLevel === 'log'
            ? error
            : undefined;
        break;
      case 'warn':
        logFn =
          logLevel === 'warn' || logLevel === 'debug' || logLevel === 'info' || logLevel === 'log'
            ? warn
            : undefined;
        break;
      case 'debug':
        logFn =
          logLevel === 'debug' || logLevel === 'info' || logLevel === 'log' ? debug : undefined;
        break;
      case 'info':
        logFn = logLevel === 'info' || logLevel === 'log' ? info : undefined;
        break;
      default:
        logFn = logLevel === 'log' ? log : undefined;
    }
    if (!!logFn) {
      logFn(`- ${dayjs().format('DD.MM.YYYY, HH:mm:ss:SSSZ[Z]')} --`, ...values);
    }
  };

// removes emojis
export const sanitizeString = (value: string): string => {
  return value.replace(
    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
    ''
  );
};

export const getCurrentHostClient = (window?: Window | null) => {
  return window ? `${window.location.protocol}//${window.location.host}` : '';
};

export const getVariantProductData = (
  product: ExtendedProduct,
  productPackages: ApiProductsCacheResponse[]
) => {
  const { earliestProduct, filteredProductPackages } = getEarliestProduct(product, productPackages);
  const cheapestProduct = earliestProduct?.products?.reduce((total, current) => {
    return (current.startingPriceAmount || 0) <= (total.startingPriceAmount || 0) ? current : total;
  }, earliestProduct?.products?.[0]);
  const code = cheapestProduct?.packageCode || '';
  const foundVariant = product.variants?.find(variant => {
    return code === variant.name;
  });

  return {
    foundVariant,
    cheapestProduct,
    filteredProductPackages
  };
};

const isClient = (): boolean => {
  return typeof window !== 'undefined';
};

export default isClient;
