import { getSortedCacheProductsByDate } from '@/components/SearchForResults/helperFns';
import { getEarliestPackage } from '@/templates/country/ProductPageInnerGetSelectedPackage';
import { ApiProductsCacheResponse } from '@ibe/api';
import { ApiProduct } from '../../../api/model';

export const getEarliestProduct = (
  product: ApiProduct,
  productPackages: ApiProductsCacheResponse[]
): {
  filteredProductPackages: ApiProductsCacheResponse[];
  earliestProduct: ApiProductsCacheResponse;
} => {
  const filteredProductPackages = productPackages.filter(
    productPackage =>
      productPackage.packageCode === product.name ||
      (!!product.variants &&
        product.variants.map(variant => variant.name).includes(productPackage?.packageCode || ''))
  );
  const sortedProductPackages = getSortedCacheProductsByDate(filteredProductPackages);
  return { filteredProductPackages, earliestProduct: getEarliestPackage(sortedProductPackages) };
};
