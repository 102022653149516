/* tslint:disable */
/* eslint-disable */
/**
 * Albatros CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
    import {
        ApiImage,
        ApiImageFromJSON,
        ApiImageFromJSONTyped,
        ApiImageToJSON,
    } from './';

/**
* 
* @export
* @interface ApiExtra
*/
export interface ApiExtra {
    /**
    * 
    * @type {string}
    * @memberof ApiExtra
    */
    id?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiExtra
    */
    name: string;
    /**
    * 
    * @type {string}
    * @memberof ApiExtra
    */
    cmsCode: string;
    /**
    * 
    * @type {string}
    * @memberof ApiExtra
    */
    category: string;
    /**
    * 
    * @type {string}
    * @memberof ApiExtra
    */
    defaultPickUpStationCode?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiExtra
    */
    defaultDropOffStationCode?: string;
    /**
    * 
    * @type {string}
    * @memberof ApiExtra
    */
    description?: string;
    /**
    * 
    * @type {ApiImage}
    * @memberof ApiExtra
    */
    image?: ApiImage;
    /**
    * 
    * @type {ApiImage}
    * @memberof ApiExtra
    */
    illustration?: ApiImage;
}

export function ApiExtraFromJSON(json: any): ApiExtra {
return ApiExtraFromJSONTyped(json, false);
}

export function ApiExtraFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiExtra {
    if ((json === undefined) || (json === null)) {
    return json;
    }
    return {
    
                    'id': json['id'],
                    'name': json['name'],
                    'cmsCode': json['cmsCode'],
                    'category': json['category'],
                    'defaultPickUpStationCode': json['defaultPickUpStationCode'],
                    'defaultDropOffStationCode': json['defaultDropOffStationCode'],
                    'description': json['description'],
                            'image': ApiImageFromJSON(json['image']),
                            'illustration': ApiImageFromJSON(json['illustration']),
        };
    }

    export function ApiExtraToJSON(value?: ApiExtra | null): any {
    if (value === undefined) {
    return undefined;
    }
    if (value === null) {
    return null;
    }
    return {

                'id': value.id,
                'name': value.name,
                'cmsCode': value.cmsCode,
                'category': value.category,
                'defaultPickUpStationCode': value.defaultPickUpStationCode,
                'defaultDropOffStationCode': value.defaultDropOffStationCode,
                'description': value.description,
                            'image': ApiImageToJSON(value.image),
                            'illustration': ApiImageToJSON(value.illustration),
    };
    }


    // custom code here ##################################################################################################################
    // function toUTC(date) {
    //   var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
    //   return new Date(date_utc);
    // }

