import { InitOptions } from 'i18next';
import LocalStorageBackend from 'i18next-localstorage-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import HttpBackend from 'i18next-http-backend';

export enum Language {
  DA = 'da',
  FI = 'fi',
  NO = 'no',
  PL = 'pl',
  SV = 'sv'
}

export const languages = Object.values(Language);

export enum Currency {
  EUR = 'EUR',
  DKK = 'DKK',
  NO = 'NO'
}

export const languageMapping: Record<
  string,
  { language: string; nationality: string; currency: string }
> = {
  da: {
    language: 'da-DK',
    nationality: 'DNK',
    currency: 'DKK'
  },
  sv: {
    language: 'sv-SE',
    nationality: 'SWE',
    currency: 'SEK'
  },
  no: {
    language: 'no-NO',
    nationality: 'NOR',
    currency: 'NOK'
  },
  fi: {
    language: 'fi-FI',
    nationality: 'FIN',
    currency: 'EUR'
  },
  pl: {
    language: 'pl-PL',
    nationality: 'POL',
    currency: 'PLN'
  }
};

export const cookieInformationLanguageMapping: Record<string, string> = {
  da: 'DA',
  sv: 'SV',
  no: 'NB',
  fi: 'FI',
  pl: 'PL'
};

export const trustPilotLanguageMapping: Record<
  string,
  { locale: string; site: string; businessUnitId: string; logoUrls: string[] }
> = {
  da: {
    locale: 'da-DK',
    site: 'www.albatros-travel.dk',
    businessUnitId: '4690535000006400050002ed',
    logoUrls: ['/logos/fdih.png', '/logos/drf.png', '/logos/rejsegaranti_fonden.png']
  },
  sv: {
    locale: 'sv-SE',
    site: 'albatros.se',
    businessUnitId: '601a8b6540bb78000169f2d1',
    logoUrls: ['/logos/rejsegaranti_fonden.png']
  },
  no: {
    locale: 'no-NO',
    site: 'albatros.no',
    businessUnitId: '63494c0e0dced035df137178',
    logoUrls: ['/logos/fdih.png', '/logos/drf.png', '/logos/rejsegaranti_fonden.png']
  },
  fi: {
    locale: 'fi-FI',
    site: 'albatros-travel.fi',
    businessUnitId: '53aa8807dec7e10d38f59f32',
    logoUrls: [
      '/logos/fdih.png',
      '/logos/drf.png',
      '/logos/rejsegaranti_fonden.png',
      '/logos/smal_afta.png'
    ]
  },
  pl: {
    locale: 'pl-PL',
    site: 'albatros.pl',
    businessUnitId: '53aa8807dec7e10d38f59f32',
    logoUrls: ['/logos/fdih.png', '/logos/drf.png', '/logos/rejsegaranti_fonden.png']
  }
};

export const specialNumberFormat: string[] = [Language.NO, Language.SV];

export const defaultNumberFormat: string[] = [Language.FI, Language.PL];

export const currencyMapping: Record<string, string> = {
  da: ' kr.',
  sv: ':-',
  no: ',—',
  fi: '€',
  pl: ' zł'
};

export const fallbackCurrency: string = Currency.DKK;

export const fallbackLng: string = Language.DA;

export const lngCookieName: string = 'i18next';

export const nameSpaces = [
  'Checkout',
  'city',
  'ClimateTable',
  'ContactBar',
  'continent',
  'continentMap',
  'country',
  'extensionTeaser',
  'factsAccordion',
  'footer',
  'galleryOverview',
  'generalForHold',
  'Header',
  'hero',
  'product',
  'productTeaser',
  'search',
  'topProductTeaser',
  'TravelInformation',
  'travelTypeTeaser'
];

export function getOptionsClient(
  lng: string = fallbackLng,
  ns?: string | string[],
  isLocal?: string,
  magnoliaHost?: string,
  translationsVersion?: string,
  activateCache?: boolean
): InitOptions {
  return {
    // debug: process.env.NODE_ENV === 'development',
    debug: false,
    compatibilityJSON: 'v4',
    preload: typeof window === 'undefined' ? [...languages] : [],
    supportedLngs: [lng],
    fallbackLng: false,
    lng,
    fallbackNS: false,
    load: 'currentOnly',
    initImmediate: true,
    interpolation: { escapeValue: true },
    backend: {
      backends: [
        ...(isLocal !== 'true' ? [LocalStorageBackend, HttpBackend] : []),
        resourcesToBackend(
          (lng: string, ns: string) =>
            import(`../../../public/translations/generated/${lng}/${ns}.json`)
        )
      ],
      backendOptions: [
        {
          expirationTime: 10 * 60 * 1000
        },
        {
          loadPath: `${magnoliaHost}/.rest/translations/{{ns}}.{{lng}}.json`,
          reloadInterval: activateCache ? 10 * 60 * 1000 : 0,
          expirationTime: activateCache ? 10 * 60 * 1000 : 0,
          queryStringParams: { v: translationsVersion }
        }
      ]
    },
    ns: ns || nameSpaces
  };
}
